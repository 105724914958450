
import moment from 'moment'
export default {
  data() {
    return {
      wallet: '',
      phase1Time: [
        moment(new Date('Sun Aug 01 2021 11:00:00 GMT+0800')).format('YYYY-MM-DD HH:mm:ss'),
        moment(new Date('Sun Aug 03 2021 11:00:00 GMT+0800')).format('YYYY-MM-DD HH:mm:ss')
      ],
      phase2Time: [
        moment(new Date('Sun Aug 04 2021 11:00:00 GMT+0800')).format('YYYY-MM-DD HH:mm:ss'),
        moment(new Date('Sun Aug 27 2021 00:00:00 GMT+0800')).format('YYYY-MM-DD HH:mm:ss')
      ]
    }
  },
  mounted() {
    this.wallet = this.$route.query.wallet

  },
}
